import { useState, useEffect } from "react";
import { Dialog } from "@headlessui/react";
import axios from "axios";
import { getToken } from "utils/getToken";
import { toast } from "react-toastify";
import SyncLoader from "components/Loaders/SyncLoader";
import { IoMdClose } from "react-icons/io";
import {
  convertTimeTo12HourFormat,
  convertTimeTo24HourFormat,
} from "utils/formatTime";

const headers = {
  "Content-Type": "application/json",
  Authorization: `Token ${getToken()}`,
};

const weekDays = [
  { title: "Monday", value: "Mon" },
  { title: "Tuesday", value: "Tue" },
  { title: "Wednesday", value: "Wed" },
  { title: "Thursday", value: "Thu" },
  { title: "Friday", value: "Fri" },
  { title: "Saturday", value: "Sat" },
  { title: "Sunday", value: "Sun" },
];

const EditSlotModal = ({
  openEditSlotModal,
  setOpenEditSlotModal,
  setIsDataUpdated,
}) => {
  const [loading, setLoading] = useState(false);

  const [formValues, setFormValues] = useState({
    day: openEditSlotModal?.slotDetails?.day || "",
    time_from: openEditSlotModal?.slotDetails?.time_from || "01:00",
    time_to: openEditSlotModal?.slotDetails?.time_to || "01:00",
    status: openEditSlotModal?.slotDetails?.status,
  });

  const [scheduleFormValues, setScheduleFormValues] = useState({
    date: openEditSlotModal?.slotDetails?.date || "",
    time_from: openEditSlotModal?.slotDetails?.time_from || "",
    time_to: openEditSlotModal?.slotDetails?.time_to || "",
  });

  const handleCloseModal = () => {
    setOpenEditSlotModal({
      open: false,
      slotId: null,
      type: null,
      slotDetails: null,
      doctorId: null,
    });

    setFormValues({
      day: "",
      time_from: "01:00",
      time_to: "01:00",
    });

    setScheduleFormValues({
      date: "",
      time_from: "01:00",
      time_to: "01:00",
    });
  };

  const handleWeeklySlotUpdate = async (e) => {
    e.preventDefault();

    if (openEditSlotModal?.slotId || openEditSlotModal?.doctorId) {
      try {
        setLoading(true);

        const updateWeeklySlotUrl = `doctors/update-weekly-availability/`;

        const addWeeklySlotUrl = `doctors/create-weekly-availability/`;
        let response;

        if (openEditSlotModal.slotId) {
          response = await axios.post(
            process.env.REACT_APP_API_URL + updateWeeklySlotUrl,
            {
              id: openEditSlotModal.slotId,
              ...formValues,
              slot_type: openEditSlotModal.slotType,
            },
            {
              headers,
            }
          );
        }

        if (openEditSlotModal.doctorId) {
          response = await axios.post(
            process.env.REACT_APP_API_URL + addWeeklySlotUrl,
            {
              doctor: openEditSlotModal.doctorId,
              ...formValues,
              slot_type: openEditSlotModal.slotType,
            },
            {
              headers,
            }
          );
        }
        if (response.status === 200 || 201) {
          toast.success(
            `This weekly slot ${
              openEditSlotModal?.feature === "add" ? "created" : "updated"
            } successfully!`
          );
          setIsDataUpdated(true);
          handleCloseModal();
        }
      } catch (error) {
        console.error("Update_Weekly_Slot_MODAL", error);

        if (error?.response?.data?.error) {
          toast.error(error?.response?.data?.error);
        } else {
          toast.error("Something went wrong!");
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const handleScheduleSlotUpdate = async (e) => {
    e.preventDefault();

    if (openEditSlotModal?.slotId || openEditSlotModal?.doctorId) {
      try {
        setLoading(true);

        const updateAppointmentSlotUrl = `doctors/update-appointment-slot/`;

        const addAppointmentSlotUrl = `doctors/create-appointment-slot/`;
        let response;

        if (openEditSlotModal.slotId) {
          response = await axios.post(
            process.env.REACT_APP_API_URL + updateAppointmentSlotUrl,
            {
              id: openEditSlotModal.slotId,
              status: "deleted",
              slot_type: openEditSlotModal.slotType,
            },
            {
              headers,
            }
          );
        }

        if (openEditSlotModal.doctorId) {
          response = await axios.post(
            process.env.REACT_APP_API_URL + addAppointmentSlotUrl,
            {
              doctor: openEditSlotModal.doctorId,
              ...scheduleFormValues,
              slot_type: openEditSlotModal.slotType,
            },
            {
              headers,
            }
          );
        }
        if (response.status === 200 || 201) {
          toast.success(
            `This appointment slot ${
              openEditSlotModal?.feature === "add" ? "created" : "Deleted"
            } successfully!`
          );
          setIsDataUpdated(true);
          handleCloseModal();
        }
      } catch (error) {
        console.error("Update_Weekly_Slot_MODAL", error);

        if (error?.response?.data?.error) {
          toast.error(error?.response?.data?.error);
        } else {
          toast.error("Something went wrong!");
        }
      } finally {
        setLoading(false);
      }
    }
  };

  // console.log("Form Values", formValues);

  // console.log("Schedule Values", scheduleFormValues);

  // console.log("Open Edit Modal", openEditSlotModal);

  useEffect(() => {
    if (openEditSlotModal?.type === "weeklySlot") {
      setFormValues({
        day: openEditSlotModal?.slotDetails?.day || "",
        time_from: openEditSlotModal?.slotDetails?.time_from || "01:00",
        time_to: openEditSlotModal?.slotDetails?.time_to || "01:00",
        status: openEditSlotModal?.slotDetails?.status,
      });
    }

    if (openEditSlotModal?.type === "scheduleSlot") {
      setScheduleFormValues({
        date: openEditSlotModal?.slotDetails?.date || "",
        time_from: openEditSlotModal?.slotDetails?.time_from || "01:00",
        time_to: openEditSlotModal?.slotDetails?.time_to || "01:00",
      });
    }
  }, [openEditSlotModal?.slotId]);

  const getFormBySlotType = (slotType) => {
    if (slotType === "weeklySlot") {
      return (
        <form onSubmit={handleWeeklySlotUpdate} className="space-y-4">
          <div className="mt-4">
            <label
              htmlFor="day"
              className="block text-sm font-medium text-gray-700"
            >
              Day
            </label>
            <select
              id="day"
              name="day"
              value={formValues.day}
              onChange={(e) =>
                setFormValues((prev) => ({
                  ...prev,
                  day: e.target.value,
                }))
              }
              className={`mt-2 w-full rounded border p-3 text-sm`}
            >
              <option value="">Select day</option>
              {weekDays.map((day, index) => (
                <option key={index} value={day.value}>
                  {day.title}
                </option>
              ))}
            </select>
          </div>

          <div className="mt-4">
            <label
              htmlFor="fromTime"
              className="block text-sm font-medium text-gray-700"
            >
              From Time
            </label>
            <div className="flex">
              <select
                id="fromTimeHour"
                name="fromTimeHour"
                value={
                  formValues.time_from
                    ? convertTimeTo12HourFormat(formValues.time_from).hour
                    : "01"
                }
                onChange={(e) => {
                  const selectedHour = e.target.value;
                  const selectedMinute =
                    convertTimeTo12HourFormat(formValues.time_from).minute ||
                    "00";
                  const selectedMeridiem =
                    convertTimeTo12HourFormat(formValues.time_from).meridiem ||
                    "AM";
                  setFormValues((prev) => ({
                    ...prev,
                    time_from: convertTimeTo24HourFormat({
                      hour: selectedHour,
                      minute: selectedMinute,
                      meridiem: selectedMeridiem,
                    }),
                  }));
                }}
                className="w-1/3 p-3 mt-2 mr-2 text-sm border rounded"
              >
                {[...Array(12).keys()].map((hour) => (
                  <option key={hour + 1} value={hour + 1}>
                    {hour + 1}
                  </option>
                ))}
              </select>
              <select
                id="fromTimeMinute"
                name="fromTimeMinute"
                value={convertTimeTo12HourFormat(formValues.time_from).minute}
                onChange={(e) =>
                  setFormValues((prev) => ({
                    ...prev,
                    time_from: convertTimeTo24HourFormat({
                      hour:
                        convertTimeTo12HourFormat(formValues.time_from).hour ||
                        "01",
                      minute: e.target.value || "00",
                      meridiem: convertTimeTo12HourFormat(formValues.time_from)
                        .meridiem,
                    }),
                  }))
                }
                className="w-1/3 p-3 mt-2 text-sm border rounded"
              >
                {[0, 15, 30, 45].map((minute) => (
                  <option
                    key={minute}
                    value={minute < 10 ? `0${minute}` : minute}
                  >
                    {minute < 10 ? `0${minute}` : minute}
                  </option>
                ))}
              </select>
              <select
                id="fromTimeMeridiem"
                name="fromTimeMeridiem"
                value={convertTimeTo12HourFormat(formValues.time_from).meridiem}
                onChange={(e) =>
                  setFormValues((prev) => ({
                    ...prev,
                    time_from: convertTimeTo24HourFormat({
                      hour:
                        convertTimeTo12HourFormat(formValues.time_from).hour ||
                        "01",
                      minute:
                        convertTimeTo12HourFormat(formValues.time_from)
                          .minute || "00",
                      meridiem: e.target.value,
                    }),
                  }))
                }
                className="w-1/3 p-3 mt-2 ml-2 text-sm border rounded"
              >
                <option value="AM">AM</option>
                <option value="PM">PM</option>
              </select>
            </div>
          </div>

          <div className="mt-4">
            <label
              htmlFor="toTime"
              className="block text-sm font-medium text-gray-700"
            >
              To Time
            </label>
            <div className="flex">
              <select
                id="toTimeHour"
                name="toTimeHour"
                value={
                  formValues.time_to
                    ? convertTimeTo12HourFormat(formValues.time_to).hour
                    : "01"
                }
                onChange={(e) => {
                  const selectedHour = e.target.value;
                  const selectedMinute =
                    convertTimeTo12HourFormat(formValues.time_to).minute ||
                    "00";
                  const selectedMeridiem =
                    convertTimeTo12HourFormat(formValues.time_to).meridiem ||
                    "AM";
                  setFormValues((prev) => ({
                    ...prev,
                    time_to: convertTimeTo24HourFormat({
                      hour: selectedHour,
                      minute: selectedMinute,
                      meridiem: selectedMeridiem,
                    }),
                  }));
                }}
                className="w-1/3 p-3 mt-2 mr-2 text-sm border rounded"
              >
                {[...Array(12).keys()].map((hour) => (
                  <option key={hour + 1} value={hour + 1}>
                    {hour + 1}
                  </option>
                ))}
              </select>
              <select
                id="toTimeMinute"
                name="toTimeMinute"
                value={convertTimeTo12HourFormat(formValues.time_to).minute}
                onChange={(e) =>
                  setFormValues((prev) => ({
                    ...prev,
                    time_to: convertTimeTo24HourFormat({
                      hour:
                        convertTimeTo12HourFormat(formValues.time_to).hour ||
                        "01",
                      minute: e.target.value || "00",
                      meridiem: convertTimeTo12HourFormat(formValues.time_to)
                        .meridiem,
                    }),
                  }))
                }
                className="w-1/3 p-3 mt-2 text-sm border rounded"
              >
                {[0, 15, 30, 45].map((minute) => (
                  <option
                    key={minute}
                    value={minute < 10 ? `0${minute}` : minute}
                  >
                    {minute < 10 ? `0${minute}` : minute}
                  </option>
                ))}
              </select>
              <select
                id="toTimeMeridiem"
                name="toTimeMeridiem"
                value={convertTimeTo12HourFormat(formValues.time_to).meridiem}
                onChange={(e) =>
                  setFormValues((prev) => ({
                    ...prev,
                    time_to: convertTimeTo24HourFormat({
                      hour:
                        convertTimeTo12HourFormat(formValues.time_to).hour ||
                        "01",
                      minute:
                        convertTimeTo12HourFormat(formValues.time_to).minute ||
                        "00",
                      meridiem: e.target.value,
                    }),
                  }))
                }
                className="w-1/3 p-3 mt-2 ml-2 text-sm border rounded"
              >
                <option value="AM">AM</option>
                <option value="PM">PM</option>
              </select>
            </div>
          </div>
          <div className="mt-4">
            <label
              htmlFor="day"
              className="block text-sm font-medium text-gray-700"
            >
              Status
            </label>
            <select
              id="status"
              name="status"
              value={formValues.status}
              onChange={(e) =>
                setFormValues((prev) => ({
                  ...prev,
                  status: e.target.value,
                }))
              }
              className={`mt-2 w-full rounded border p-3 text-sm`}
            >
              <option value="active">Active</option>
              <option value="inactive">InActive</option>
            </select>
          </div>

          <div className="mt-4">
            {loading ? (
              <div>
                <div className="px-4 py-2 text-white bg-blue-600 rounded-md">
                  <SyncLoader size="5px" />
                </div>
              </div>
            ) : (
              <button
                type="submit"
                className="w-full px-4 py-2 text-white bg-blue-600 rounded-md"
              >
                {openEditSlotModal?.feature === "add" ? "Add" : "Update"} Slot
              </button>
            )}
          </div>
        </form>
      );
    }

    if (slotType === "scheduleSlot") {
      return (
        <form onSubmit={handleScheduleSlotUpdate} className="space-y-4">
          <div className="mt-4">
            <label
              htmlFor="day"
              className="block text-sm font-medium text-gray-700"
            >
              Date
            </label>

            {openEditSlotModal?.feature === "edit" ? (
              <p>{scheduleFormValues.date}</p>
            ) : (
              <input
                type="date"
                value={scheduleFormValues.date}
                onChange={(e) => {
                  setScheduleFormValues((prev) => ({
                    ...prev,
                    date: e.target.value,
                  }));
                }}
                disabled={openEditSlotModal?.feature === "edit"}
                className={`w-full rounded-md border-gray-200 p-2 disabled:cursor-not-allowed`}
              />
            )}
          </div>

          <div className="mt-4">
            <label
              htmlFor="fromTime"
              className="block text-sm font-medium text-gray-700"
            >
              From Time
            </label>
            {openEditSlotModal?.feature === "edit" ? (
              <p>{scheduleFormValues.time_from}</p>
            ) : (
              <div className="flex">
                <select
                  id="fromTimeHour"
                  name="fromTimeHour"
                  value={
                    scheduleFormValues.time_from
                      ? convertTimeTo12HourFormat(scheduleFormValues.time_from)
                          .hour
                      : "01"
                  }
                  onChange={(e) => {
                    const selectedHour = e.target.value;
                    const selectedMinute =
                      convertTimeTo12HourFormat(scheduleFormValues.time_from)
                        .minute || "00";
                    const selectedMeridiem =
                      convertTimeTo12HourFormat(scheduleFormValues.time_from)
                        .meridiem || "AM";
                    setScheduleFormValues((prev) => ({
                      ...prev,
                      time_from: convertTimeTo24HourFormat({
                        hour: selectedHour,
                        minute: selectedMinute,
                        meridiem: selectedMeridiem,
                      }),
                    }));
                  }}
                  className="w-1/3 p-3 mt-2 mr-2 text-sm border rounded"
                >
                  {[...Array(12).keys()].map((hour) => (
                    <option key={hour + 1} value={hour + 1}>
                      {hour + 1}
                    </option>
                  ))}
                </select>
                <select
                  id="fromTimeMinute"
                  name="fromTimeMinute"
                  value={
                    convertTimeTo12HourFormat(scheduleFormValues.time_from)
                      .minute
                  }
                  onChange={(e) =>
                    setScheduleFormValues((prev) => ({
                      ...prev,
                      time_from: convertTimeTo24HourFormat({
                        hour:
                          convertTimeTo12HourFormat(
                            scheduleFormValues.time_from
                          ).hour || "01",
                        minute: e.target.value || "00",
                        meridiem: convertTimeTo12HourFormat(
                          scheduleFormValues.time_from
                        ).meridiem,
                      }),
                    }))
                  }
                  className="w-1/3 p-3 mt-2 text-sm border rounded"
                >
                  {[0, 15, 30, 45].map((minute) => (
                    <option
                      key={minute}
                      value={minute < 10 ? `0${minute}` : minute}
                    >
                      {minute < 10 ? `0${minute}` : minute}
                    </option>
                  ))}
                </select>
                <select
                  id="fromTimeMeridiem"
                  name="fromTimeMeridiem"
                  value={
                    convertTimeTo12HourFormat(scheduleFormValues.time_from)
                      .meridiem
                  }
                  onChange={(e) =>
                    setScheduleFormValues((prev) => ({
                      ...prev,
                      time_from: convertTimeTo24HourFormat({
                        hour:
                          convertTimeTo12HourFormat(
                            scheduleFormValues.time_from
                          ).hour || "01",
                        minute:
                          convertTimeTo12HourFormat(
                            scheduleFormValues.time_from
                          ).minute || "00",
                        meridiem: e.target.value,
                      }),
                    }))
                  }
                  className="w-1/3 p-3 mt-2 ml-2 text-sm border rounded"
                >
                  <option value="AM">AM</option>
                  <option value="PM">PM</option>
                </select>
              </div>
            )}
          </div>

          <div className="mt-4">
            <label
              htmlFor="toTime"
              className="block text-sm font-medium text-gray-700"
            >
              To Time
            </label>
            {openEditSlotModal?.feature === "edit" ? (
              <p>{scheduleFormValues.time_to}</p>
            ) : (
              <div className="flex">
                <select
                  id="toTimeHour"
                  name="toTimeHour"
                  value={
                    scheduleFormValues.time_to
                      ? convertTimeTo12HourFormat(scheduleFormValues.time_to)
                          .hour
                      : "01"
                  }
                  onChange={(e) => {
                    const selectedHour = e.target.value;
                    const selectedMinute =
                      convertTimeTo12HourFormat(scheduleFormValues.time_to)
                        .minute || "00";
                    const selectedMeridiem =
                      convertTimeTo12HourFormat(scheduleFormValues.time_to)
                        .meridiem || "AM";
                    setScheduleFormValues((prev) => ({
                      ...prev,
                      time_to: convertTimeTo24HourFormat({
                        hour: selectedHour,
                        minute: selectedMinute,
                        meridiem: selectedMeridiem,
                      }),
                    }));
                  }}
                  className="w-1/3 p-3 mt-2 mr-2 text-sm border rounded"
                >
                  {[...Array(12).keys()].map((hour) => (
                    <option key={hour + 1} value={hour + 1}>
                      {hour + 1}
                    </option>
                  ))}
                </select>
                <select
                  id="toTimeMinute"
                  name="toTimeMinute"
                  value={
                    convertTimeTo12HourFormat(scheduleFormValues.time_to).minute
                  }
                  onChange={(e) =>
                    setScheduleFormValues((prev) => ({
                      ...prev,
                      time_to: convertTimeTo24HourFormat({
                        hour:
                          convertTimeTo12HourFormat(scheduleFormValues.time_to)
                            .hour || "01",
                        minute: e.target.value || "00",
                        meridiem: convertTimeTo12HourFormat(
                          scheduleFormValues.time_to
                        ).meridiem,
                      }),
                    }))
                  }
                  className="w-1/3 p-3 mt-2 text-sm border rounded"
                >
                  {[0, 15, 30, 45].map((minute) => (
                    <option
                      key={minute}
                      value={minute < 10 ? `0${minute}` : minute}
                    >
                      {minute < 10 ? `0${minute}` : minute}
                    </option>
                  ))}
                </select>
                <select
                  id="toTimeMeridiem"
                  name="toTimeMeridiem"
                  value={
                    convertTimeTo12HourFormat(scheduleFormValues.time_to)
                      .meridiem
                  }
                  onChange={(e) =>
                    setScheduleFormValues((prev) => ({
                      ...prev,
                      time_to: convertTimeTo24HourFormat({
                        hour:
                          convertTimeTo12HourFormat(scheduleFormValues.time_to)
                            .hour || "01",
                        minute:
                          convertTimeTo12HourFormat(scheduleFormValues.time_to)
                            .minute || "00",
                        meridiem: e.target.value,
                      }),
                    }))
                  }
                  className="w-1/3 p-3 mt-2 ml-2 text-sm border rounded"
                >
                  <option value="AM">AM</option>
                  <option value="PM">PM</option>
                </select>
              </div>
            )}
          </div>

          <div className="mt-4">
            {loading ? (
              <div>
                <div
                  className={`rounded-md ${
                    openEditSlotModal?.feature === "edit"
                      ? "bg-red-600"
                      : "bg-blue-600"
                  } px-4 py-2 text-white`}
                >
                  <SyncLoader size="5px" />
                </div>
              </div>
            ) : (
              <button
                type="submit"
                className={`w-full rounded-md ${
                  openEditSlotModal?.feature === "edit"
                    ? "bg-red-600"
                    : "bg-blue-600"
                } px-4 py-2 text-white`}
              >
                {openEditSlotModal?.feature === "add" ? "Add" : "Delete"}{" "}
                Appointment Slot
              </button>
            )}
          </div>
        </form>
      );
    }

    return null;
  };

  return (
    <Dialog
      open={openEditSlotModal.open}
      onClose={() => {
        handleCloseModal();
      }}
      className="relative z-50"
    >
      {/* The backdrop, rendered as a fixed sibling to the panel container */}
      <div className="fixed inset-0 bg-[#000] opacity-20" aria-hidden="true" />

      {/* Full-screen scrollable container */}
      <div className="fixed inset-0 overflow-y-auto">
        {/* Container to center the panel */}
        <div className="flex items-center justify-center min-h-screen p-4">
          {/* The actual dialog panel  */}
          <Dialog.Panel className="mx-auto w-full max-w-md rounded md:min-w-[30%]">
            <div className="inline-block w-full p-6 my-8 bg-white shadow-xl">
              <div className="flex justify-end">
                <button
                  onClick={() => {
                    handleCloseModal();
                  }}
                  className="flex gap-3 text-gray-600 hover:text-gray-800"
                >
                  <IoMdClose size={25} />
                </button>
              </div>
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-center text-gray-900"
              >
                {openEditSlotModal?.type === "weeklySlot"
                  ? `${
                      openEditSlotModal?.feature === "add" ? "Add" : "Edit"
                    } Weekly Slot`
                  : `${
                      openEditSlotModal?.feature === "add"
                        ? "Add Appointment Slot"
                        : `Are you sure you want to delete ${openEditSlotModal?.slotDetails?.doctorName}'s this Appointment Slot?`
                    } `}
              </Dialog.Title>

              {getFormBySlotType(openEditSlotModal?.type)}
            </div>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
};

export default EditSlotModal;
