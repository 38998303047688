import { useState, useEffect } from "react";
import { FaPencilAlt } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";
import { parseISO, format } from "date-fns";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import { useMediaQuery } from "@mui/material";
import { getToken } from "utils/getToken";
import SyncLoader from "components/Loaders/SyncLoader";
import CreateSubscriptionModal from "components/CreateSubscription/CreateSubscriptionForm";
import { HiExternalLink } from "react-icons/hi";
import UserDataPopup from "components/UserData/UserDataPopup";
import IframeModal from "../ChatStatus/IframeModal";
import { MdCancel, MdBlock } from "react-icons/md";
import Select from "react-select";

const Growth = () => {
  const [token, setToken] = useState(getToken());
  const [data, setData] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [filteredData, setFilteredData] = useState(data);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [filterPage, setFilterPage] = useState("");
  const [comment, setComment] = useState("");
  const [editableMaxCalls, setEditableMaxCalls] = useState("");
  const [isCreateSubscriptionModalOpen, setIsCreateSubscriptionModalOpen] =
    useState(false);
  const [isSearchButtonClicked, setIsSearchButtonClicked] = useState(false);
  const [reasonData, setReasonData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fetchingSubscriptionData, setFetchingSubscriptionData] =
    useState(true);
  const [isPDataModalOpen, setIsPDataModalOpen] = useState(false);
  const [isFilterSelectedForConsultation, setIsFilterSelectedForConsultation] =
    useState(false);
  const [consultationTypeFilter, setConsultationTypeFilter] = useState("");
  const [selectedStatusFilters, setSelectedStatusFilters] = useState("");
  const [selectedFilterSubs, setselectedFilterSubs] = useState("");
  const [growthDoctorsData, setGrowthDoctorsData] = useState([]);
  const [selectedGrowthDoctorId, setSelectedGrowthDoctorId] = useState("");
  const [disableDoctorDate, setDisableDoctorDate] = useState(false);
  const [selectedDate, setSelectedDate] = useState({
    start_date: "",
    end_date: "",
  });

  const fetchData = async () => {
    setFetchingSubscriptionData(true);
    try {
      const requestData = {
        doctor_id: selectedGrowthDoctorId,
        plan_name: selectedFilterSubs,
        start_date: selectedDate?.start_date,
        end_date: selectedDate?.end_date,
        status: selectedStatusFilters,
        number: filterText,
      };

      // Remove empty, null, or undefined values
      const filteredRequestData = Object.fromEntries(
        Object.entries(requestData).filter(
          ([_, value]) => value !== "" && value !== null && value !== undefined
        )
      );

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}subscription/growth-consultation-dashboard/`,
        filteredRequestData,
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setFetchingSubscriptionData(false);
    }
  };

  useEffect(() => {
    if (!isSearchButtonClicked) {
      fetchData();
    }
  }, [
    currentPage,
    isSearchButtonClicked,
    consultationTypeFilter,
    selectedDate.start_date,
    selectedDate.end_date,
    selectedGrowthDoctorId,
    selectedStatusFilters,
    selectedFilterSubs,
    filterText,
  ]);

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    const today = new Date().toISOString().split("T")[0];

    setSelectedDate((prev) => {
      let updatedDates = { ...prev, [name]: value };

      if (!value) {
        return { ...prev, [name]: "" };
      }

      if (name === "start_date" && !prev.end_date) {
        updatedDates.end_date = today;
      }

      return updatedDates;
    });
  };
  const handleSearchButtonClick = async () => {
    setLoading(true); // Set loading to true before starting the search

    try {
      console.log("searching text");
      await fetchData(); // Call the API with the updated filterText
      setIsSearchButtonClicked(true); // Set the flag to true indicating search button is clicked
    } catch (error) {
      console.log("No data Available");
    }

    setLoading(false); // Set loading to false after the search button click is complete
  };

  useEffect(() => {
    filterDueCalls(); // selectedStatusFilters changes
  }, [selectedStatusFilters, data]);

  const filterDueCalls = () => {
    if (!selectedStatusFilters) {
      setFilteredData(data); // Show all if no filter is selected
      return;
    }
  };
  const fetchGrowthDoctors = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}doctors/doctors/all/`,
        {
          params: { category: "growth", list_type: "all" },
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      setGrowthDoctorsData(response.data);

      console.log("Growth Doctors Data:", response.data);
    } catch (error) {
      console.error("Error fetching growth doctors:", error);
    }
  };
  useEffect(() => {
    fetchGrowthDoctors();
  }, []);

  const handleStatusSubsChange = (newSelectedStatuses) => {
    setselectedFilterSubs(newSelectedStatuses);
  };

  const handlePDataModalClose = () => {
    setIsPDataModalOpen(false);
    setSelectedUser(null);
  };

  const handlePDataModal = (row) => {
    setSelectedUser(row.phone_number);
    // console.log(row.Chat_started_by);
    setIsPDataModalOpen(true);
  };
  const handleFilterChangeForStatus = (value) => {
    setSelectedStatusFilters(value);
    fetchData();
    setCurrentPage(1);
  };

  const handlePageSearch = () => {
    const pageNumber = parseInt(filterPage);
    if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= totalPages) {
      setFilterText(""); // Clear the filterText after navigation
      setIsSearchButtonClicked(false); // Reset the search button click state

      setCurrentPage(pageNumber);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleFilterChange = (e) => {
    const { value } = e.target;
    setFilterText(value);
    setIsSearchButtonClicked(false); // Reset the search button click state when filter text changes
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleCommentChange = (e) => {
    const { value } = e.target;
    setComment(value);
  };

  const handleMaxCallsChange = (e) => {
    const { value } = e.target;
    setEditableMaxCalls(value);
  };

  const isSmallScreen = useMediaQuery("(max-width: 768px)");
  const numberOfButtons = isSmallScreen ? 4 : 10;

  // Function to toggle the modal's visibility
  const toggleModal = () => {
    setIsCreateSubscriptionModalOpen(!isCreateSubscriptionModalOpen);
  };

  const handleRefresh = async () => {
    fetchData();
  };

  const handleFilterChangeForConsultationType = (value) => {
    setConsultationTypeFilter(value);
    setIsFilterSelectedForConsultation(true);
    setCurrentPage(1);
  };

  // For navigating to that particular appointment
  const [highlightedRow, setHighlightedRow] = useState(null);
  const [modalContent, setModalContent] = useState(null);
  function createLinkAndOpenAppointment(row, rowIndex) {
    const url = `/admin/appointment?mobile_number=${row.phone_number.slice(
      1
    )}&status=0&type=1}`;

    setHighlightedRow(rowIndex);
    setModalContent({ url });
  }
  const closeModal = () => {
    setModalContent(null);
  };
  const fetchOriginalData = async () => {
    setLoading(true);
    try {
      await fetchData(); // Fetch the default data
    } catch (error) {
      console.log("Error fetching original data:", error);
    }
    setLoading(false);
  };

  return (
    <div className="mx-auto px-4 md:mx-0 md:w-full md:p-4">
      <div className="my-6 flex items-center justify-between">
        <h1 className="items-center justify-center text-center text-4xl font-bold capitalize text-navy-700 dark:text-white">
          Growth
        </h1>
        {modalContent && (
          <IframeModal onClose={closeModal} url={modalContent.url} />
        )}
        <div className="flex flex-col items-center justify-between md:flex-row">
          <div className="flex items-center gap-3">
            <div className="hidden md:block">
              <button
                disabled={fetchingSubscriptionData}
                className="rounded-lg bg-blue-500 px-3 py-2.5 text-white hover:bg-blue-600 md:px-6"
                onClick={() => handleRefresh()}
              >
                Refresh
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-[1fr,2fr]">
        {/* Filter input */}
        <div className="flex items-center justify-between gap-4">
          <div className="relative mr-2 flex w-full max-w-[300px] items-center gap-3 rounded-lg bg-white p-1 shadow-lg dark:bg-navy-800 md:w-[300px]">
            {/* Input Field */}
            <div className="relative  flex w-full items-center gap-2 rounded-lg border border-gray-300 bg-white px-3 py-2 shadow-sm dark:border-navy-700 dark:bg-navy-900">
              <input
                type="text"
                placeholder="Phone"
                value={filterText}
                onChange={handleFilterChange}
                className="bg-transparent w-full rounded-md text-sm text-navy-700 placeholder-gray-400 outline-none dark:text-white dark:placeholder-gray-300"
              />

              {(filterText.length > 0 || isSearchButtonClicked) && (
                <button
                  className="absolute right-3 text-gray-400 transition hover:text-gray-600 dark:text-gray-300 dark:hover:text-gray-100"
                  onClick={(e) => {
                    e.stopPropagation();
                    setFilterText("");
                    setIsSearchButtonClicked(false);
                    fetchOriginalData();
                  }}
                >
                  <MdCancel size={18} />
                </button>
              )}
            </div>

            {/* Search Button */}
            <button
              className="flex items-center justify-center rounded-lg bg-blue-500 px-5 py-2 text-white shadow-md transition hover:bg-blue-600 disabled:opacity-50 dark:bg-blue-700 dark:hover:bg-blue-800"
              onClick={handleSearchButtonClick}
              disabled={loading}
            >
              {loading ? "Loading..." : "Search"}
            </button>
          </div>

          <div className="md:hidden">
            <button
              disabled={fetchingSubscriptionData}
              className="rounded-lg bg-blue-500 px-3 py-2.5 text-white hover:bg-blue-600 md:px-6"
              onClick={() => handleRefresh()}
            >
              Refresh
            </button>
          </div>
        </div>

        {/* Filters */}
        <div className="my-4 flex items-center justify-end">
          <div className="flex items-center justify-start gap-4">
            {/* Filter by Doctor ID */}
            <div className="relative flex items-center">
              <Select
                className="focus:ring-primary min-w-[200px] cursor-pointer rounded-md border-none bg-white text-base hover:bg-gray-50 focus:outline-none focus:ring-2"
                name="growth_doctor_id"
                isDisabled={disableDoctorDate}
                onChange={(selectedOption) => {
                  setSelectedGrowthDoctorId(selectedOption?.value);
                  if (selectedOption?.value) {
                    setSelectedStatusFilters("completed");
                    setDisableDoctorDate(false);
                  }
                }}
                value={
                  growthDoctorsData
                    .map((doctor) => ({ value: doctor.id, label: doctor.name }))
                    .find(
                      (option) => option.value === selectedGrowthDoctorId
                    ) || {
                    value: "",
                    label: "doctor",
                  }
                }
                options={[
                  { value: "", label: "doctor" },
                  ...growthDoctorsData.map((doctor) => ({
                    value: doctor.id,
                    label: doctor.name,
                  })),
                ]}
                placeholder="doctor"
              />

              {selectedGrowthDoctorId && (
                <button
                  className="absolute right-10 top-1/2 -translate-y-1/2 cursor-pointer text-red-500"
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedGrowthDoctorId("");
                    setSelectedStatusFilters("");
                    fetchOriginalData();
                  }}
                >
                  <MdCancel />
                </button>
              )}

              {disableDoctorDate && (
                <span className="absolute right-3 cursor-not-allowed text-gray-500">
                  <MdBlock />
                </span>
              )}
            </div>

            {/*filter by subscription */}

            <div className="relative">
              <select
                className="focus:ring-primary rounded-md border border-gray-300 bg-white px-3 py-2 text-base ring-opacity-5 hover:bg-gray-50 focus:outline-none focus:ring-1"
                value={selectedFilterSubs}
                onChange={(e) => handleStatusSubsChange(e.target.value)}
              >
                <option value="">plan</option>

                <option value="BTP">BTP</option>
                <option value="HOLISTIC_NEW">Holistic</option>
                <option value="ESSENTIAL">Essential</option>
              </select>

              {selectedFilterSubs && selectedFilterSubs != "" && (
                <span
                  className="absolute right-3.5 top-1/2 -translate-y-1/2 cursor-pointer text-red-500"
                  onClick={(e) => {
                    e.stopPropagation();
                    setselectedFilterSubs("");
                  }}
                >
                  <MdCancel />
                </span>
              )}
            </div>

            {/* Filter by call due */}
            <div className="flex">
              <select
                className="focus:ring-primary rounded-md border border-gray-300 bg-white px-3 py-2 text-base ring-opacity-5 hover:bg-gray-50 focus:outline-none focus:ring-1"
                value={selectedStatusFilters}
                onChange={(e) => {
                  const value = e.target.value;
                  setSelectedStatusFilters(value);

                  if (value && value !== "completed") {
                    setSelectedGrowthDoctorId("");
                    setSelectedDate({ start_date: "", end_date: "" });
                    setDisableDoctorDate(true);
                  } else {
                    setDisableDoctorDate(false);
                  }
                }}
              >
                <option value="due">due</option>
                <option
                  value="due_in_3d"
                  className={
                    selectedStatusFilters === "due_in_3d"
                      ? "text-green-500"
                      : "text-black"
                  }
                >
                  due in 3 days
                </option>
                <option
                  value="due_in_7d"
                  className={
                    selectedStatusFilters === "due_in_7d"
                      ? "text-green-500"
                      : "text-black"
                  }
                >
                  due in 1 week
                </option>
                <option
                  value="completed"
                  className={
                    selectedStatusFilters === "completed"
                      ? "text-green-500"
                      : "text-black"
                  }
                >
                  completed
                </option>
              </select>

              {selectedStatusFilters &&
                selectedStatusFilters !== "" &&
                selectedStatusFilters !== "due" && (
                  <span
                    className="relative right-8 top-3 cursor-pointer text-red-500"
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedStatusFilters("");
                      setDisableDoctorDate(false);
                      setSelectedDate({ start_date: "", end_date: "" });
                    }}
                  >
                    <MdCancel />
                  </span>
                )}
            </div>

            {/* Start Date Input */}
            <div className="-mt-6 flex flex-col">
              <label>Appointment Date- Start</label>
              <div className="relative flex w-full max-w-[250px] items-center gap-2 rounded-md border border-gray-300 bg-white shadow-sm dark:border-navy-700 dark:bg-navy-900">
                <input
                  type="date"
                  name="start_date"
                  value={selectedDate.start_date || ""}
                  onChange={(e) => {
                    handleDateChange(e);
                    setSelectedStatusFilters("completed");
                    setDisableDoctorDate(false);
                  }}
                  disabled={disableDoctorDate}
                  className="w-full rounded-md p-2 text-sm text-navy-700 outline-none dark:text-white"
                />

                {selectedDate.start_date && !disableDoctorDate && (
                  <button
                    className="absolute right-8 text-red-500 transition"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDateChange({
                        target: { name: "start_date", value: "" },
                      });
                      setSelectedStatusFilters("");
                    }}
                  >
                    <MdCancel />
                  </button>
                )}

                {disableDoctorDate && (
                  <span className="absolute right-4 cursor-not-allowed text-gray-500">
                    <MdBlock />
                  </span>
                )}
              </div>
            </div>

            {/* End Date Input */}
            <div className="-mt-6 flex flex-col">
              <label>Appointment Date- End</label>
              <div className="relative flex w-full max-w-[250px] items-center gap-2 rounded-md border border-gray-300 bg-white shadow-sm dark:border-navy-700 dark:bg-navy-900">
                <input
                  type="date"
                  name="end_date"
                  value={selectedDate.end_date || ""}
                  min={selectedDate.start_date || ""}
                  onChange={(e) => {
                    handleDateChange(e);
                    setSelectedStatusFilters("completed");
                    setDisableDoctorDate(false);
                  }}
                  disabled={disableDoctorDate}
                  className="w-full rounded-md p-2 text-sm text-navy-700 outline-none dark:text-white"
                />

                {selectedDate.end_date && !disableDoctorDate && (
                  <button
                    className="absolute right-8 text-red-500 transition"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDateChange({
                        target: { name: "end_date", value: "" },
                      });
                      setSelectedStatusFilters("");
                    }}
                  >
                    <MdCancel />
                  </button>
                )}

                {disableDoctorDate && (
                  <span className="absolute right-4 cursor-not-allowed text-gray-500">
                    <MdBlock />
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Filters end */}
      </div>


      {/* new way to show user data in modal */}
      {isPDataModalOpen && (
        <UserDataPopup
          selectedUser={selectedUser}
          noSearch={true}
          handlePDataModalClose={handlePDataModalClose}
        />
      )}

      {/* Desktop View Data */}
      {!fetchingSubscriptionData && data.length > 0 ? (
        <div className="min-h-[60vh] max-w-full overflow-y-auto">
          <table className="hidden table-auto bg-white shadow sm:block">
            <thead>
              <tr className="bg-blue-200">
                <th className="border px-4 py-2">User</th>
                <th className="border px-4 py-2">Plan Name</th>
                <th className="border px-4 py-2">Start Date</th>
                <th className="border px-4 py-2">End Date</th>
                <th className="border px-4 py-2">Last Growth Call</th>
                <th className="border px-4 py-2">Appointment Details</th>
                <th className="border px-4 py-2">Call Due</th>
              </tr>
            </thead>
            <tbody>
              {data.map((row, rowIndex) => (
                <tr
                  key={rowIndex}
                  className={`${
                    rowIndex === highlightedRow
                      ? "bg-yellow-500"
                      : "hover:bg-blue-100"
                  }`}
                >
                  <td className="border px-4 py-2">
                    <button
                      className="cursor-pointer text-blue-500 hover:underline"
                      onClick={() => {
                        handlePDataModal(row);
                      }}
                    >
                      <p>{row.phone_number}</p> <HiExternalLink />
                      <p className="text-blue-500">{row?.name || ""}</p>
                    </button>
                  </td>
                  <td className="border px-4 py-2">{row.plan_name}</td>
                  <td className="border px-4 py-2">
                    {format(parseISO(row.s_start_time), "dd-MM-yyyy hh:mm a")}
                  </td>
                  <td className="border px-4 py-2">
                    {format(parseISO(row.s_end_time), "dd-MM-yyyy hh:mm a")}
                  </td>

                  <td className="border px-4 py-2">
                    {row.last_appointment_time === null
                      ? "No Call taken"
                      : format(
                          parseISO(row.last_appointment_time),
                          "dd-MM-yyyy hh:mm a"
                        )}
                    <br />
                    {row.last_appointment_time === null ? (
                      ""
                    ) : (
                      <p className="text-green-600 ">by {row.doctor_name}</p>
                    )}
                  </td>
                  <td className="border px-4 py-2">
                    <button
                      className="cursor-pointer text-blue-500 hover:underline"
                      onClick={() => {
                        createLinkAndOpenAppointment(row, rowIndex);
                      }}
                    >
                      Open Appointnment <HiExternalLink />
                    </button>
                  </td>
                  <td className="border px-4 py-2">
                    {" "}
                    {row.due_since_days} days
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : fetchingSubscriptionData ? (
        <div className="flex h-[70vh] items-center justify-center">
          <SyncLoader color={"#525CEB"} size={10} />
        </div>
      ) : (
        <div className="flex h-full min-h-[60vh] w-full items-center justify-center">
          <p className="text-center text-lg font-semibold">
            No Subscription Data available
          </p>
        </div>
      )}
      <div className="mt-10 flex flex-col justify-center md:mt-20 md:flex-row">
        <div className="flex justify-center md:ml-auto">
          <button
            onClick={handlePrevPage}
            disabled={currentPage === 1}
            className="rounded-lg bg-blue-500 px-4 py-2 text-white hover:bg-blue-600 md:px-8"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M9.707 4.293a1 1 0 010 1.414L6.414 9H16a1 1 0 110 2H6.414l3.293 3.293a1 1 0 01-1.414 1.414l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 0z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          {totalPages > 1 &&
            [
              ...Array(Math.min(numberOfButtons, totalPages - currentPage + 1)),
            ].map((_, index) => {
              const pageNumber = currentPage + index;
              return (
                <button
                  key={index}
                  onClick={() => setCurrentPage(pageNumber)}
                  disabled={pageNumber > totalPages}
                  className={`ml-2 rounded-lg px-4 py-2 ${
                    currentPage === pageNumber
                      ? "bg-blue-500 text-white"
                      : "bg-gray-200 text-gray-800 hover:bg-gray-300"
                  }`}
                >
                  {pageNumber}
                </button>
              );
            })}

          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
            className="mx-2 rounded-lg bg-blue-500 px-4 py-2 text-white hover:bg-blue-600 md:px-8"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 -rotate-180 transform"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M9.707 4.293a1 1 0 010 1.414L6.414 9H16a1 1 0 110 2H6.414l3.293 3.293a1 1 0 01-1.414 1.414l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 0z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
        <div className="relative mt-5 flex items-center justify-end md:ml-auto md:mt-0 ">
          <input
            type="text"
            placeholder={`Go to page (1 - ${totalPages})`}
            value={filterPage}
            onChange={(e) => setFilterPage(e.target.value)}
            className="w-full rounded-md border border-gray-400 bg-white py-2 pl-8 pr-2 text-sm font-medium text-gray-800 outline-none"
          />
          <button
            className="mx-2 cursor-pointer rounded-lg bg-blue-500 px-4 py-3 text-white hover:bg-blue-300"
            onClick={handlePageSearch}
          >
            <FiSearch
              className="h-4 w-4 cursor-pointer rounded-lg"
              onClick={handlePageSearch}
            />
          </button>
        </div>
      </div>

      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default Growth;
