import { useState, useEffect } from "react";
import { Dialog } from "@headlessui/react";
import { useRecoilState } from "recoil";
import axios from "axios";
import { getToken } from "utils/getToken";
import { toast } from "react-toastify";
import SyncLoader from "components/Loaders/SyncLoader";
import { whatsappSessionModalState } from "store/whatsappSession/atoms/whatsappSessionAtomState";
import { IoMdClose } from "react-icons/io";
import { formatToDateAndTime } from "utils/formatTime";
import {
  startSessionModalState,
  isSessionDetailsUpdatedState,
} from "store/whatsappSession/atoms/whatsappSessionAtomState";
import { useSetRecoilState } from "recoil";
import { Listbox } from "@headlessui/react";
import { HiOutlineSelector } from "react-icons/hi";
import { FaCheck } from "react-icons/fa";

const headers = {
  "Content-Type": "application/json",
  Authorization: `Token ${getToken()}`,
};

const sessionTypesOptions = [
  {
    value: "send-group-message",
    name: "Send Group Message",
  },
  {
    value: "bn-doctors",
    name: "Bn Doctors",
  },
  {
    value: "bulk-message",
    name: "Bulk Message",
  },
  {
    value: "group-automation",
    name: "Group Automation",
  },
  {
    value: "read-message",
    name: "Read Message",
  },
];

const sessionStatusOptions = [
  {
    value: "created",
    name: "Created",
  },
  {
    value: "connected",
    name: "Connected",
  },
  {
    value: "disconnected",
    name: "Disconnected",
  },
  {
    value: "deleted",
    name: "Deleted",
  },
  {
    value: "error",
    name: "Error",
  },
];

const validatePhone = (phone) => {
  const phoneRegex = /^(\+?\d{1,3})?\d{10}$/;
  if (!phoneRegex.test(phone)) {
    toast.error("Please enter a valid phone number.");
    return false;
  } else {
    return true;
  }
};

const EditSessionModal = () => {
  const [whatsappSessionModal, setWhatsappSessionModal] = useRecoilState(
    whatsappSessionModalState
  );
  const [fetchingStatus, setFetchingStatus] = useState(false);
  const [sessionStatus, setSessionStatus] = useState("");
  const [updatingSessionDetails, setUpdatingSessionDetails] = useState(false);
  const [creatingSession, setCreatingSession] = useState(false);
  const [fetchingSessionDetails, setFetchingSessionDetails] = useState(false);
  const setStartSessionModal = useSetRecoilState(startSessionModalState);
  const setIsSessionDetailsUpdated = useSetRecoilState(
    isSessionDetailsUpdatedState
  );

  const [formValues, setFormValues] = useState({
    session_name: whatsappSessionModal?.sessionDetails?.session_name || "",
    description: whatsappSessionModal?.sessionDetails?.description || "",
    type: whatsappSessionModal?.sessionDetails?.type || [],
    status: whatsappSessionModal?.sessionDetails?.status || "",
    phone_number: whatsappSessionModal?.sessionDetails?.phone_number || "",
    created_at: whatsappSessionModal?.sessionDetails?.created_at || "",
    last_login_at: whatsappSessionModal?.sessionDetails?.last_login_at || "",
    last_used_at: whatsappSessionModal?.sessionDetails?.last_used_at || "",
    updated_at: whatsappSessionModal?.sessionDetails?.updated_at || "",
  });

  const selectedSessionId = whatsappSessionModal?.sessionId;
  const selectedSessionDeatils = whatsappSessionModal?.sessionDetails;



  const handleUpdateSessionDetails = async (e) => {
    e.preventDefault();

    if (selectedSessionId) {
      try {
        setUpdatingSessionDetails(true);

        // const updatedAppointmentData = JSON.stringify({
        //   id: selectedSessionId,
        //   description: formValues.description,
        //   type: formValues.type,
        //   status: formValues.status
        // });

        const config = {
          method: "post",
          url: process.env.REACT_APP_API_URL + "whatsapp/edit-session/",
          headers: headers,
          data: {
            id: selectedSessionId,
            description: formValues.description,
            type: formValues.type,
            status: formValues.status === "deleted" ? formValues.status : null,
          },
        };

        // Update the data on the server using Axios
        const updateSessionResponse = await axios(config);

        if (updateSessionResponse.status === 200) {
          toast.success("Successfully Updated Session details!");
          setIsSessionDetailsUpdated(true);
          setWhatsappSessionModal({
            open: false,
            sessionDetails: null,
            sessionId: null,
          });
        }
      } catch (error) {
        console.error("UPDATE_SESSION_DETAILS_ERROR", error);
        toast.error("Something went wrong while updating session details!");
      } finally {
        setUpdatingSessionDetails(false);
      }
    }
  };

  const handleCreateSession = async (e) => {
    e.preventDefault();

    if (formValues.session_name && validatePhone(formValues.phone_number)) {
      try {
        setCreatingSession(true);

        const jsonPayloadData = JSON.stringify({
          session_name: formValues.session_name,
          phone_number: formValues.phone_number,
          description: formValues.description,
          type: formValues.type,
        });

        const config = {
          method: "post",
          url: process.env.REACT_APP_API_URL + "whatsapp/create-session/",
          headers: headers,
          data: {
            session_name: formValues.session_name,
            phone_number: formValues.phone_number,
            description: formValues.description,
            type: formValues.type,
          },
        };

        const createSessionResponse = await axios(config);

        if (createSessionResponse.status === 200) {
          toast.success("Session is created successfully!");
          setIsSessionDetailsUpdated(true);
          setWhatsappSessionModal({
            open: false,
            sessionDetails: null,
            sessionId: null,
          });
        }
      } catch (error) {
        console.error("CREATE_WHATSAPP_SESSION_ERROR", error);
        toast.error("Something went wrong while creating session!");
      } finally {
        setCreatingSession(false);
      }
    }
  };

  const getEditSessionForm = () => {
    return (
      <div>
        <div className="my-2 rounded-md bg-gray-100 py-4 px-2">
          <div className="flex flex-col gap-3 md:flex-row md:justify-between md:gap-0">
            <div className="flex items-center justify-between gap-2">
              <h3>Session Status</h3>
              <div className="flex items-center justify-center gap-3">
                {fetchingStatus ? (
                  <div
                    role="status"
                    className="max-w-sm animate-pulse bg-white"
                  >
                    <div className="h-3.5 w-32 rounded-md bg-gray-400"></div>
                  </div>
                ) : (
                  <p className="flex items-center text-sm font-medium text-gray-900 me-3">
                    <span
                      className={`flex h-2.5 w-2.5 flex-shrink-0 rounded-full ${
                        sessionStatus === "connected"
                          ? "bg-green-500"
                          : "bg-red-500"
                      } me-1.5`}
                    ></span>
                    {sessionStatus}
                  </p>
                )}

                {/* <button
                  onClick={getSessionStatusById}
                  disabled={fetchingStatus}
                  className=""
                >
                  <AiOutlineReload size={20} />
                </button> */}
              </div>
            </div>

            <div className="">
              <button
                type="button"
                onClick={() => {
                  setWhatsappSessionModal({
                    open: false,
                    sessionDetails: null,
                    sessionId: null,
                    type: null,
                  });

                  setStartSessionModal({
                    open: true,
                    sessionId: selectedSessionId,
                    sessionName: selectedSessionDeatils.session_name,
                    sessionStatus: selectedSessionDeatils.status,
                  });
                }}
                className="rounded-md bg-red-500 px-3 py-2 font-light text-white"
              >
                Start Session
              </button>
            </div>
          </div>
        </div>

        {/* <div className="my-4 grid grid-cols-2 gap-4 bg-gray-100 py-4 px-2">
          <div>
            <h3>Session Name - {selectedSessionDeatils?.session_name}</h3>
          </div>
          <div>
            <h3>Phone Number - {selectedSessionDeatils?.phone_number}</h3>
          </div>
          <div>
            <h3>Start - {selectedSessionDeatils?.created_at}</h3>
          </div>
          <div>
            <h3>Last Login - {selectedSessionDeatils?.last_login_at}</h3>
          </div>
          <div>
            <h3>Last Used - {selectedSessionDeatils?.last_used_at}</h3>
          </div>
          <div>
            <h3>Last Updated - {selectedSessionDeatils?.updated_at}</h3>
          </div>
        </div> */}
        <form onSubmit={handleUpdateSessionDetails} className="mt-4 space-y-4">
          <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
            <div className="w-full text-sm">
              <p className="my-1.5 text-start font-semibold">Session Name</p>
              <input
                value={formValues.session_name}
                readOnly
                placeholder="Enter a name for session"
                className="w-full resize-none rounded-md border border-gray-100 bg-gray-100 px-3 py-2"
              />
            </div>

            <div className="w-full text-sm">
              <p className="my-1.5 text-start font-semibold">Description</p>
              <input
                value={formValues.description}
                onChange={(e) =>
                  setFormValues((prev) => {
                    return {
                      ...prev,
                      description: e.target.value,
                    };
                  })
                }
                placeholder="Enter a description for session"
                className="w-full resize-none rounded-md border-2 border-gray-600 px-3 py-2"
              />
            </div>

            <div className="w-full text-sm">
              <p className="my-1.5 text-start font-semibold">Session Type</p>
              <Listbox
                value={formValues.type}
                onChange={(selectedSessions) => {
                  setFormValues((prev) => {
                    return {
                      ...prev,
                      type: selectedSessions,
                    };
                  });
                }}
                multiple
              >
                {({ open }) => (
                  <>
                    <div className="relative inline-block w-full text-left">
                      <Listbox.Button className="inline-flex w-full items-center justify-between rounded-md border-2 border-gray-600 px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                        {formValues.type.length > 0
                          ? `${formValues.type.length} Type Selected`
                          : "Select Session Type"}
                        <HiOutlineSelector size="22" />
                      </Listbox.Button>
                    </div>
                    <Listbox.Options
                      className="absolute mt-1 max-h-60 min-w-[250px] overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-opacity-5 focus:outline-none sm:text-sm"
                      style={{ display: open ? "block" : "none" }}
                    >
                      {sessionTypesOptions.map((option, index) => (
                        <Listbox.Option
                          key={index}
                          value={option.value}
                          className={({ active }) =>
                            `${
                              active
                                ? "bg-blue-500 text-white"
                                : "text-gray-900"
                            }
                        relative cursor-pointer select-none py-2 pl-10 pr-4`
                          }
                        >
                          {({ selected, active }) => (
                            <>
                              <span
                                className={`${
                                  selected ? "font-semibold" : "font-normal"
                                }`}
                              >
                                {option.name}
                              </span>
                              {selected ? (
                                <span
                                  className={`${
                                    active ? "text-white" : "text-blue-500"
                                  }
                              absolute inset-y-0 left-0 flex items-center pl-3`}
                                >
                                  <FaCheck size="15" />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </>
                )}
              </Listbox>
            </div>

            <div className="w-full text-sm">
              <p className="my-1.5 text-start font-semibold">Session Status:</p>
              <select
                value={formValues.status}
                onChange={(e) => {
                  setFormValues((prev) => {
                    return {
                      ...prev,
                      status: e.target.value,
                    };
                  });
                }}
                className={`w-full rounded-md border-2 border-gray-600 p-2.5`}
              >
                {sessionStatusOptions.map((option, index) => (
                  <option
                    key={index}
                    value={option.value}
                    disabled={option.value !== "deleted"}
                  >
                    {option.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="w-full text-sm">
              <p className="my-1.5 text-start font-semibold">Phone Number</p>
              <input
                readOnly
                value={formValues.phone_number}
                className="w-full rounded-md border border-gray-100 bg-gray-100 px-3 py-2"
              />
            </div>

            <div className="w-full text-sm">
              <p className="my-1.5 text-start font-semibold">Start Date</p>
              <input
                readOnly
                value={
                  formValues.created_at
                    ? formatToDateAndTime(formValues.created_at)
                    : ""
                }
                className="w-full rounded-md border border-gray-100 bg-gray-100 px-3 py-2"
              />
            </div>

            {formValues.last_login_at && (
              <div className="w-full text-sm">
                <p className="my-1.5 text-start font-semibold">
                  Last Login Date
                </p>
                <input
                  readOnly
                  value={
                    formValues.last_login_at
                      ? formatToDateAndTime(formValues.last_login_at)
                      : ""
                  }
                  className="w-full rounded-md border border-gray-100 bg-gray-100 px-3 py-2"
                />
              </div>
            )}

            {formValues.last_used_at && (
              <div className="w-full text-sm">
                <p className="my-1.5 text-start font-semibold">
                  Last Used Date
                </p>

                <input
                  readOnly
                  value={
                    formValues.last_used_at
                      ? formatToDateAndTime(formValues.last_used_at)
                      : ""
                  }
                  className="w-full rounded-md border border-gray-100 bg-gray-100 px-3 py-2"
                />
              </div>
            )}

            {formValues.updated_at && (
              <div className="w-full text-sm">
                <p className="my-1.5 text-start font-semibold">Updated At</p>

                <input
                  readOnly
                  value={
                    formValues.updated_at
                      ? formatToDateAndTime(formValues.updated_at)
                      : ""
                  }
                  className="w-full rounded-md border border-gray-100 bg-gray-100 px-3 py-2"
                />
              </div>
            )}
          </div>

          <div className="mt-4">
            {updatingSessionDetails ? (
              <div>
                <div className="rounded-md bg-blue-500 px-4 py-2 text-white">
                  <SyncLoader size="5px" />
                </div>
              </div>
            ) : (
              <div className="flex w-full flex-col gap-3">
                <button
                  type="submit"
                  className="w-full rounded-md bg-blue-500 px-4 py-2 text-white"
                >
                  Update Session Details
                </button>
              </div>
            )}
          </div>
        </form>
      </div>
    );
  };

  const getCreateSessionForm = () => {
    return (
      <div>
        <form onSubmit={handleCreateSession} className="mt-4 space-y-4">
          <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
            <div className="w-full text-sm">
              <p className="my-1.5 text-start font-semibold">Session Name</p>
              <input
                value={formValues.session_name}
                required
                onChange={(e) =>
                  setFormValues((prev) => {
                    const newSessionName = e.target.value.replace(/\s/g, "");

                    return {
                      ...prev,
                      session_name: newSessionName,
                    };
                  })
                }
                placeholder="Enter a name for session"
                className="w-full rounded-md border border-gray-100 bg-gray-100 px-3 py-2"
              />
            </div>

            <div className="w-full text-sm">
              <p className="my-1.5 text-start font-semibold">Description</p>
              <input
                value={formValues.description}
                required
                onChange={(e) =>
                  setFormValues((prev) => {
                    return {
                      ...prev,
                      description: e.target.value,
                    };
                  })
                }
                placeholder="Enter a description for session"
                className="w-full rounded-md border border-gray-100 bg-gray-100 px-3 py-2"
              />
            </div>

            <div className="w-full text-sm">
              <p className="my-1.5 text-start font-semibold">Phone Number</p>
              <input
                value={formValues.phone_number}
                type="number"
                onChange={(e) =>
                  setFormValues((prev) => {
                    return {
                      ...prev,
                      phone_number: e.target.value,
                    };
                  })
                }
                placeholder="Enter a mobile number"
                onWheel={(event) => event.currentTarget.blur()}
                className="w-full rounded-md border border-gray-100 bg-gray-100 px-3 py-2 [&::-webkit-inner-spin-button]:appearance-none"
              />
            </div>

            <div className="w-full text-sm">
              <p className="my-1.5 text-start font-semibold">Session Type</p>
              <Listbox
                value={formValues.type}
                onChange={(selectedSessions) => {
                  setFormValues((prev) => {
                    return {
                      ...prev,
                      type: selectedSessions,
                    };
                  });
                }}
                multiple
              >
                {({ open }) => (
                  <>
                    <div className="relative inline-block w-full text-left">
                      <Listbox.Button className="inline-flex w-full items-center justify-between rounded-md border border-gray-300 bg-gray-100 px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                        {formValues.type.length > 0
                          ? `${formValues.type.length} Type Selected`
                          : "Select Session Type"}
                        <HiOutlineSelector size="22" />
                      </Listbox.Button>
                    </div>
                    <Listbox.Options
                      className="absolute mt-1 max-h-60 min-w-[250px] overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-opacity-5 focus:outline-none sm:text-sm"
                      style={{ display: open ? "block" : "none" }}
                    >
                      {sessionTypesOptions.map((option, index) => (
                        <Listbox.Option
                          key={index}
                          value={option.value}
                          className={({ active }) =>
                            `${
                              active
                                ? "bg-blue-500 text-white"
                                : "text-gray-900"
                            }
                        relative cursor-pointer select-none py-2 pl-10 pr-4`
                          }
                        >
                          {({ selected, active }) => (
                            <>
                              <span
                                className={`${
                                  selected ? "font-semibold" : "font-normal"
                                }`}
                              >
                                {option.name}
                              </span>
                              {selected ? (
                                <span
                                  className={`${
                                    active ? "text-white" : "text-blue-500"
                                  }
                              absolute inset-y-0 left-0 flex items-center pl-3`}
                                >
                                  <FaCheck size="15" />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </>
                )}
              </Listbox>
            </div>
          </div>

          <div className="mt-4">
            {creatingSession ? (
              <div>
                <div className="rounded-md bg-blue-500 px-4 py-2 text-white">
                  <SyncLoader size="5px" />
                </div>
              </div>
            ) : (
              <button className="w-full rounded-md bg-blue-500 px-4 py-2 text-white">
                Create Session
              </button>
            )}
          </div>
        </form>
      </div>
    );
  };

  const getSessionDeatilsById = async () => {
    if (selectedSessionId) {
      try {
        setFetchingSessionDetails(true);
        // Make the API call to get the data for the selected user
        const sessionDetailsResponse = await axios.get(
          process.env.REACT_APP_API_URL +
            `whatsapp/get-session/?id=${selectedSessionId}`,
          {
            headers,
          }
        );

        if (sessionDetailsResponse.status === 200) {
          if (sessionDetailsResponse.data) {
            setFormValues({
              session_name: sessionDetailsResponse.data?.session_name || "",
              description: sessionDetailsResponse.data?.description || "",
              type: sessionDetailsResponse.data?.type || [],
              status: sessionDetailsResponse.data?.status || "",
              phone_number: sessionDetailsResponse.data?.phone_number || "",
              created_at: sessionDetailsResponse.data?.created_at || "",
              last_login_at: sessionDetailsResponse.data?.last_login_at || "",
              last_used_at: sessionDetailsResponse.data?.last_used_at || "",
              updated_at: sessionDetailsResponse.data?.updated_at || "",
            });
            if (sessionDetailsResponse.data?.status) {
              setSessionStatus(sessionDetailsResponse.data.status);
            }
          }
        }
      } catch (error) {
        console.error("SESSION_DETAILS_BY_ID_DATA_ERROR", error);
      } finally {
        setFetchingSessionDetails(false);
      }
    }
  };

  useEffect(() => {
    if (selectedSessionId) {
      getSessionDeatilsById();
    }
  }, [selectedSessionId]);


  return (
    <Dialog
      open={whatsappSessionModal.open}
      onClose={() => {
        setWhatsappSessionModal({
          open: false,
          sessionDetails: null,
          sessionId: null,
          type: null,
        });
      }}
      className="relative z-50"
    >
      {/* The backdrop, rendered as a fixed sibling to the panel container */}
      <div className="fixed inset-0 bg-[#000] opacity-20" aria-hidden="true" />

      {/* Full-screen scrollable container */}
      <div className="fixed inset-0 overflow-y-auto">
        {/* Container to center the panel */}
        <div className="flex min-h-screen items-center justify-center p-4">
          {/* The actual dialog panel  */}
          {fetchingSessionDetails ? (
            <div>
              <SyncLoader size="10px" color="#525CEB" />
            </div>
          ) : (
            <Dialog.Panel className="mx-auto w-full max-w-md rounded md:min-w-[40%]">
              <div className="my-8 inline-block w-full bg-white p-6 shadow-xl">
                <div className="flex justify-end">
                  <button
                    onClick={() => {
                      setWhatsappSessionModal({
                        open: false,
                        sessionDetails: null,
                        sessionId: null,
                        type: null,
                      });
                    }}
                    className="text-gray-600 hover:text-gray-800"
                  >
                    <IoMdClose size={25} />
                  </button>
                </div>
                <Dialog.Title
                  as="h3"
                  className="text-center text-lg font-medium leading-6 text-gray-900"
                >
                  {whatsappSessionModal.type === "add"
                    ? "Create Session"
                    : "Edit Session Details"}
                </Dialog.Title>
                {whatsappSessionModal.type
                  ? whatsappSessionModal.type === "add"
                    ? getCreateSessionForm()
                    : getEditSessionForm()
                  : null}
              </div>
            </Dialog.Panel>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default EditSessionModal;
